import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from './../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any, private router: Router, private auth: AuthService, private activatedRoute: ActivatedRoute) { }

  getAWSAccessRequests() {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      //const body = { "approverID": sessionStorage.getItem("wwid"), "requestID": "AWS_SSR" };
      const body = { "approverID": sessionStorage.getItem("wwid"), "requestID": "SSR" };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getaudit', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAWSAccessRequests', body, { headers: headerDict })
    }
  }
  getAWSAccounts(owner: string, query: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "owner": owner, "query": query };
      //console.log("body::::::",JSON.stringify(body));
      //console.log("headerDict::::::",JSON.stringify(headerDict));
      //const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      //return this.http.post('https://5elru92728.execute-api.us-east-1.amazonaws.com/poc/displayAWSAccountsMaster', body, { headers: myheader })
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getawsaccounts', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAWSAccounts', body, { headers: headerDict })
    }
  }

  getPMCTableData(PMCTable: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "PMCTable": PMCTable};
      //console.log("body::::::",JSON.stringify(body));
      //console.log("headerDict::::::",JSON.stringify(headerDict));
      //const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      //return this.http.post('https://5elru92728.execute-api.us-east-1.amazonaws.com/poc/displayAWSAccountsMaster', body, { headers: myheader })
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getawsaccounts', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'PMCTablesData', body, { headers: headerDict })
    }
  }
  getInActiveAWSAccounts(isActive: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "isActive": isActive};
      //console.log("body::::::",JSON.stringify(body));
      //console.log("headerDict::::::",JSON.stringify(headerDict));
      //const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      //return this.http.post('https://5elru92728.execute-api.us-east-1.amazonaws.com/poc/displayAWSAccountsMaster', body, { headers: myheader })
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getawsaccounts', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getInActiveAWSAccounts', body, { headers: headerDict })
    }
  }

  getUserCloudMemberships(userID: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "userID": userID};
      return this.http.post(environment.urlAddress + 'getUserCloudMemberships', body, { headers: headerDict })
    }
  }

  getOCIAccounts(owner: string, query: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "owner": owner, "query": query };
      //const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      //return this.http.post('https://5elru92728.execute-api.us-east-1.amazonaws.com/poc/displayAWSAccountsMaster', body, { headers: myheader })
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getawsaccounts', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getOCICompartments', body, { headers: headerDict })
    }
  }

  getAzureAccounts(owner: string, query: string, resourceType:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "owner": owner, "query": query,"resourceType":resourceType,'userRole': sessionStorage.getItem('Role'),};
      //console.log("body::::::", JSON.stringify(body));
      //const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      //return this.http.post('https://5elru92728.execute-api.us-east-1.amazonaws.com/poc/displayAWSAccountsMaster', body, { headers: myheader })
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getawsaccounts', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAzureSubscriptions', body, { headers: headerDict })
    }
  }


  manageCloudDocuments(action: string, documentID: string, documentName: string, documentType: string, documentManagedBy: string, documentLink: string, documentDisplayedOnHomePage: string, documentDescription: string, documentTags: string, documentStatus: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry'),
      }
      const body = { "action": action, "documentID": documentID, "documentName": documentName, "documentType": documentType, "documentManagedBy": documentManagedBy, "documentLink": documentLink, "documentDisplayedOnHomePage": documentDisplayedOnHomePage, "documentDescription": documentDescription, "documentTags": documentTags, "documentStatus": documentStatus };
      //console.log("body::::::",JSON.stringify(body));
      return this.http.post(environment.urlAddress + 'manageCloudDocuments', body, { headers: headerDict })
    }
  }

  getAWSAccountGroups(accountid: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "id": accountid };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAWSAccountGroups', body, { headers: headerDict })
    }
  }


  getAzureGroups(groupScope:string, resourceGroupID:string,subscriptionID:string,managementGroupID:string,resourceID:string,department:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "groupScope": groupScope, "resourceGroupID": resourceGroupID, "subscriptionID": subscriptionID, "managementGroupID": managementGroupID,"resourceID":resourceID,"department":department };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAzureGroups', body, { headers: headerDict })
    }
  }
//
getAWSGroups(groupScope:string, resourceGroupID:string,subscriptionID:string,managementGroupID:string,resourceID:string,department:string) {
  let checkSession: string;
  checkSession = this.auth.isValidSession();
  if (checkSession == "sessionInvalid") {

    this.auth.logout();
  }
  else if (checkSession == "userForceLogin") {
    sessionStorage.clear();
    this.router.navigate(['']);
  }
  else {
    const headerDict = {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem('id_token'),
      'user': sessionStorage.getItem('email'),
      'roles': sessionStorage.getItem('Role'),
      'kid': sessionStorage.getItem('KeyID'),
      'tokenexpiry': sessionStorage.getItem('tokenExpiry')
    }
    const body = { "groupScope": groupScope, "resourceGroupID": resourceGroupID, "subscriptionID": subscriptionID, "managementGroupID": managementGroupID,"resourceID":resourceID,"department":department };
    //console.log("body::::::", JSON.stringify(body));
    //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
    return this.http.post(environment.urlAddress + 'getAWSGroupRoles', body, { headers: headerDict })
  }
}
//

  manageCloudMemberships(userID:string, groupIDs:Array<any>) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "userID": userID, "groupIDs": groupIDs,"approverID": sessionStorage.getItem('email')};
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'manageUserCloudMembershipsTopic', body, { headers: headerDict })
    }
  }

  getUserPIMRoles(resourceType:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "resourceType": resourceType, "AADToken": sessionStorage.getItem('access_token'), "UserGUID": sessionStorage.getItem('UserGUID')};
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getUserPIMEligibleRoles', body, { headers: headerDict })
    }
  }

  activateUserPIMRole(resourceId:string,roleDefinitionId:string,actionType:string,reason:string,resourceType:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      var currentDate = new Date().toISOString();
      const body = { "resourceId": resourceId, "AADToken": sessionStorage.getItem('access_token'),"roleDefinitionId":roleDefinitionId, "subjectId": sessionStorage.getItem('UserGUID'),"actionType":actionType,"reason":reason,"activateTime":currentDate,"resourceType":resourceType};
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'activateUserPIMEligibleRole', body, { headers: headerDict })
    }
  }

  getAzureSubscriptionResourceGroups(owner:string, queryType:string,subscriptionID:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "owner": owner, "query": queryType, "subscriptionID": subscriptionID };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAzureResourceGroups', body, { headers: headerDict })
    }
  }

  getResourceGroupsWithResourceIAM(subscriptionID:string,queryType:string,owner:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = {"subscriptionID": subscriptionID,"queryType":queryType,"owner":owner };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAzureResourceGroupsWithResources', body, { headers: headerDict })
    }
  }

  getOCICompartmentGroups(accountid: string, compartmentName: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "azureADRef": accountid, "compartmentName": compartmentName };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/getazureadgroups', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getOCICompartmentGroups', body, { headers: headerDict })
    }
  }

  requestCloudAccess(groupName: string, groupID: string, approverID: string, accountID: string, accountIDAzureRef: string, accountName: string, comments: string, requestorID: string, requestorName: string, requestcloudType: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "groupName": groupName, "groupID": groupID, "approverID": approverID, "accountID": accountID.toString(), "accountIDAzureRef": accountIDAzureRef, "accountName": accountName, "comments": comments, "requestorID": requestorID, "requestorName": requestorName, "requestcloudType": requestcloudType };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/addaudit', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'createSSRequest', body, { headers: headerDict })
    }

  }
  actionRequestsAWSAccess(wwids: string, groupID: string, groupName: string, accountName: string, accountIDAzureRef: string, requestType: string, action: string, approverID: string, requestID: string, additionalApprovers: string, requestorName: string, delegatedUserID: string, comments: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "wwids": wwids, "groupID": groupID, "groupName": groupName, "accountName": accountName, "accountIDAzureRef": accountIDAzureRef, "requestType": requestType, "action": action, "approverID": approverID, "requestID": requestID, "additionalApprovers": additionalApprovers, "requestorName": requestorName, "delegatedUserID": delegatedUserID, "comments": comments };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/actionrequests', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'actionRequests', body, { headers: headerDict })
    }
  }
  getAuditforAWSAccess(groupID: string, userID: string, userType: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "groupID": groupID, "userID": userID, "userType": userType };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/displayaudit', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getUserRequestAndAWSGroupAudit', body, { headers: headerDict })
    }
  }

  getAWSSupportTickets(status:string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "userID": sessionStorage.getItem("wwid"),"Status":status };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/displayaudit', body, { headers: headerDict })
      return this.http.post('https://1ia8o1q1ge.execute-api.us-east-1.amazonaws.com/PRD/' + 'getConsolidatedServiceRequests', body, { headers: headerDict })
    }
  }


    updateAzureSubscription(department: string, BC: string, accountOwners: string, accountEmail: string, isActive: string, RC: string, BU: string, accountType: string, mainAccount: string, accountName: string, businessOwner: string, id: string, description: string, updatedBy: string) {
      let checkSession: string;
      checkSession = this.auth.isValidSession();
      if (checkSession == "sessionInvalid") {

        this.auth.logout();
      }
      else if (checkSession == "userForceLogin") {
        sessionStorage.clear();
        this.router.navigate(['']);
      }
      else {
        const headerDict = {
          'Content-Type': 'application/json',
          'authorization': sessionStorage.getItem('id_token'),
          'user': sessionStorage.getItem('email'),
          'roles': sessionStorage.getItem('Role'),
          'kid': sessionStorage.getItem('KeyID'),
          'tokenexpiry': sessionStorage.getItem('tokenExpiry')
        }
        const body = { "department": department, "BC": BC, "accountOwners": accountOwners, "accountEmail": accountEmail, "isActive": isActive, "RC": RC, "BU": BU, "accountType": accountType, "mainAccount": mainAccount, "accountName": accountName, "businessOwner": businessOwner, "id": id, "description": description, "updatedBy": updatedBy };
        //console.log("body::::::", JSON.stringify(body));
        //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/updateawsaccount', body, { headers: headerDict })
        return this.http.post(environment.urlAddress + 'updateAzureSubscriptions', body, { headers: headerDict })
      }
    }

  updateAWSAccount(department: string, BC: string, accountOwners: string, networkAttached: string, accountEmail: string, azureADRef: string, isActive: string, RC: string, BU: string, accountType: string, mainAccount: string, accountName: string, businessOwner: string, id: string, description: string, awsAccountName: string, updatedBy: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "department": department, "BC": BC, "accountOwners": accountOwners, "networkAttached": networkAttached, "accountEmail": accountEmail, "azureADRef": azureADRef, "isActive": isActive, "RC": RC, "BU": BU, "accountType": accountType, "mainAccount": mainAccount, "accountName": accountName, "businessOwner": businessOwner, "id": id, "description": description, "awsAccountName": awsAccountName, "updatedBy": updatedBy };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/updateawsaccount', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'updateAWSAccount', body, { headers: headerDict })
    }
  }



  createAzureADSSORequest(appidname: string, appidownername: string, appidownerWWID: string, appidBC: string, appidRC: string, appidBU: string, redirectURL: string, appid: string, appregname: string, headerresponse: string, metadataXML: string, appEnvironment: string, requestType: string, appType: string, serviceDetails: string, accessAPI: string, spID: string, ASCUrl: string, logoutRequestURL: string, logoutResponseURL: string, comments: string, requestorWWID: string, requestorName: string, BundleID: string, Packagename: string, Signaturehash: string, customredirectURL: string, InputTokConf: string, InputAPIPerm: string, claimsattribute: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "appidname": appidname,"metadataXML":metadataXML, "appidownername": appidownername, "appidownerWWID": appidownerWWID, "appidBC": appidBC, "appidRC": appidRC, "appidBU": appidBU, "redirectURL": redirectURL, "appid": appid, "appregname": appregname, "headerresponse": headerresponse, "environment": appEnvironment, "requestType": requestType, "appType": appType, "serviceDetails": serviceDetails, "accessAPI": accessAPI, "spID": spID,  "ASCUrl": ASCUrl, "logoutRequestURL": logoutRequestURL, "logoutResponseURL": logoutResponseURL, "comments": comments, "requestorWWID": requestorWWID, "requestorName": requestorName, "BundleID": BundleID, "Packagename":Packagename, "Signaturehash":Signaturehash, "customredirectURL":customredirectURL, "InputTokConf":InputTokConf, "InputAPIPerm":InputAPIPerm, "claimsattribute":claimsattribute};
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/updateawsaccount', body, { headers: headerDict })

      return this.http.post(environment.urlAddress + 'createAzureADSSORequest', body, { headers: headerDict })
    }
  }


  actionAzureADSSORequest(appidname: string, appidownername: string, appidownerWWID: string, appidBC: string, appidRC: string, appidBU: string, redirectURL: string, appid: string, appregname: string, headerresponse: string, metadataXML: string, appEnvironment: string, requestType: string, appType: string, serviceDetails: string, accessAPI: string, spID: string, ASCUrl: string, logoutRequestURL: string, logoutResponseURL: string, comments: string, requestorWWID: string, requestorName: string, requestUpdatedBy:string, requestIDValue:string, requestorCancelledComments:string, approverRejectcomments:string,OpsRejectcomments:string, OpsComments:string, actionType:string, BundleID: string, Packagename: string, Signaturehash: string, customredirectURL: string, InputTokConf: string , InputAPIPerm: string, claimsattribute: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "appidname": appidname,"metadataXML":metadataXML, "appidownername": appidownername, "appidownerWWID": appidownerWWID, "appidBC": appidBC, "appidRC": appidRC, "appidBU": appidBU, "redirectURL": redirectURL, "appid": appid, "appregname": appregname, "headerresponse": headerresponse, "environment": appEnvironment, "requestType": requestType, "appType": appType, "serviceDetails": serviceDetails, "accessAPI": accessAPI, "spID": spID,  "ASCUrl": ASCUrl, "logoutRequestURL": logoutRequestURL, "logoutResponseURL": logoutResponseURL, "comments": comments, "requestorWWID": requestorWWID, "requestorName": requestorName, "requestUpdatedBy":requestUpdatedBy, "requestIDValue":requestIDValue, "requestorCancelledComments":requestorCancelledComments, "approverRejectcomments": approverRejectcomments, "OpsRejectcomments":OpsRejectcomments, "Opscomments":OpsComments, "actionType":actionType, "BundleID": BundleID, "Packagename":Packagename, "Signaturehash":Signaturehash, "customredirectURL":customredirectURL, "InputTokConf":InputTokConf, "InputAPIPerm":InputAPIPerm, "claimsattribute":claimsattribute};
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/updateawsaccount', body, { headers: headerDict })

      return this.http.post(environment.urlAddress + 'actionAzureADSSORequest', body, { headers: headerDict })
    }
  }

  getAzureSSORequests(userWWID: string, role: string, requestType: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "userWWID": userWWID,"role":role, "requestType": requestType};
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/updateawsaccount', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAzureSSORequests', body, { headers: headerDict })
    }
  }

  updateOCICompartments(department: string, BC: string, accountOwners: string, networkAttached: string, accountEmail: string, azureADRef: string, isActive: string, RC: string, BU: string, accountType: string, mainAccount: string, accountName: string, businessOwner: string, id: string, description: string, updatedBy: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "department": department, "BC": BC, "accountOwners": accountOwners, "networkAttached": networkAttached, "accountEmail": accountEmail, "azureADRef": azureADRef, "isActive": isActive, "RC": RC, "BU": BU, "accountType": accountType, "mainAccount": mainAccount, "accountName": accountName, "businessOwner": businessOwner, "id": id, "description": description, "updatedBy": updatedBy };
      //console.log("body::::::", JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/updateawsaccount', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'updateOCICompartments', body, { headers: headerDict })
    }
  }

  sendEmails(toAddress: string, requestorID: string, requestorName: string, message: string, subject: string, accountName: string, groupName: string, messageType: string, ) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "toAddress": toAddress, "requestorID": requestorID, "requestorName": requestorName, "message": message, "subject": subject, "accountName": accountName, "groupName": groupName, "messageType": messageType };
      //console.log("body::::::",JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/sendemails', body, { headers: headerDict })
      //return this.http.post('https://65nj9gbkle.execute-api.us-east-1.amazonaws.com/DEV/sendEmails', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'sendEmails', body, { headers: headerDict })

    }

  }

  getEC2Recommendations(accountID: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "accountID": accountID };
      //console.log("body::::::",JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/actionrequests', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getEC2Recommendations', body, { headers: headerDict })
    }
  }

  getAppsFromAppRepo(appID: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = {"appID": appID  };
      //console.log("body::::::",JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/actionrequests', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getAppsFromAppRepo', body, { headers: headerDict })
    }
  }

  getComputeOptimizer(accountID: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "accountId": accountID };
      //console.log("body::::::",JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/actionrequests', body, { headers: headerDict })
      return this.http.post('https://c2tciatm70.execute-api.us-east-1.amazonaws.com/Prod/computeoptimizer', body, { headers: headerDict })
    }
  }
  getComputeOptimizer2(accountID: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      const body = { "accountId": accountID };
      //console.log("body::::::",JSON.stringify(body));
      //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/actionrequests', body, { headers: headerDict })
      return this.http.post('https://c2tciatm70.execute-api.us-east-1.amazonaws.com/Prod/computeoptimizer2', body, { headers: headerDict })
    }
  }
  /*
    getAWSInventory(accountID: string, location: string) {
      let checkSession: string;
      let targetUrl: string;
      checkSession = this.auth.isValidSession();
      if (checkSession == "sessionInvalid") {

        this.auth.logout();
      }
      else if (checkSession == "userForceLogin") {
        sessionStorage.clear();
        this.router.navigate(['']);
      }
      else {
        const headerDict = {
          'Content-Type': 'application/json',
          'authorization': sessionStorage.getItem('id_token'),
          'user': sessionStorage.getItem('email'),
          'roles': sessionStorage.getItem('Role'),
          'kid': sessionStorage.getItem('KeyID'),
          'tokenexpiry': sessionStorage.getItem('tokenExpiry')
        }
        if (location == 'CN') {
          targetUrl = environment.awsCNConfigurlAddress
        }
        else {
          targetUrl = environment.awsConfigurlAddress
        }

        const body = { "accountID": accountID };
        //console.log("body::::::",JSON.stringify(body));
        //console.log("headerDict::::::",JSON.stringify(headerDict));
        //console.log("targetUrl::::::",targetUrl);
        //return this.http.post('https://bpw59qmofi.execute-api.us-east-1.amazonaws.com/poc/actionrequests', body, { headers: headerDict })
        return this.http.post(targetUrl, body, { headers: headerDict })
      }
    } */

  public getImage() {
    try {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      return this.http.get(environment.getUserImage, { headers: headerDict });
    }
    catch (err) {
      console.log("error message:" + err['message']);

    }
  }


  getUserDetails(userName: string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      try {
        const headerDict = {
          'Content-Type': 'application/json',
          'authorization': sessionStorage.getItem('id_token'),
          'user': sessionStorage.getItem('email'),
          'roles': sessionStorage.getItem('Role'),
          'kid': sessionStorage.getItem('KeyID'),
          'tokenexpiry': sessionStorage.getItem('tokenExpiry')
        }
        const body = { "user": userName };
        return this.http.post(environment.urlAddress + 'getUserDetails', body, { headers: headerDict })
      }
      catch (err) {
        console.log("error message:" + err['message']);

      }
    }
  }

  getAllApplicationsforAudit(loggin : string) {
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      //console.log(">>>>>>>>>>>>>>>>>" , headerDict);
      const body = {"bowner":"success"}
      //return this.http.post('https://ieybbqmlwl.execute-api.us-east-1.amazonaws.com/DEV/azure30expiryget/getbusinessowner', body, { headers: headerDict })
      //console.log(this.http.post(environment.urlAddress  + 'azure30expiryget/getbusinessowner', body, { headers: headerDict }));
      return this.http.post(environment.urlAddress  + 'getkeyowner', body, { headers: headerDict })

    }
  }

  renewTheKey(loggin_User: string, Roles: string, appname: string,
    BusinessOwner:string, BusinessOwnername: string,RID:string,ID: string, BJ: string, appID: string){
      let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      let body : any;
      body = {"ids":ID,"loggedinuser":loggin_User,"appName":appname,"RID":RID, "BJ": BJ, "appID":appID}

      console.log("BODDYYYYYYYYYY",body);

      return this.http.post(environment.urlAddress + 'getazure30daysecretexpiry', body, { headers: headerDict });
    }


    }

  getExpiredApplications(loggin_User: string, Roles: string){
    let checkSession: string;
    checkSession = this.auth.isValidSession();
    if (checkSession == "sessionInvalid") {

      this.auth.logout();
    }
    else if (checkSession == "userForceLogin") {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
    else {
      const headerDict = {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem('id_token'),
        'user': sessionStorage.getItem('email'),
        'roles': sessionStorage.getItem('Role'),
        'kid': sessionStorage.getItem('KeyID'),
        'tokenexpiry': sessionStorage.getItem('tokenExpiry')
      }
      console.log(">>>>>>>>>>>>>>>>>" , headerDict);
      let body : any;
      //CHG0103526 changes starts
      // if(Roles == "CloudOps"){
      //   body = {"bowner": 'Roles'};
      // }
      //CHG0103526 changes ends
      if(Roles.includes("CloudOps")){
        body = {"bowner": 'Roles'};
      }else {
      body = {"bowner": loggin_User}; // TO BE PASSED as logged in user bowner loggin_User
      }
      console.log("BODDYYYYYYYYYY",body);
      //return this.http.post('https://ieybbqmlwl.execute-api.us-east-1.amazonaws.com/DEV/azure30expiryget/getbusinessowner', body, { headers: headerDict })
      return this.http.post(environment.urlAddress + 'getkeyowner', body, { headers: headerDict })

    }
  }

  code2Token() {

    this.activatedRoute.params.subscribe((params: Params) => {
      let id_token = params['id_token'];
      //console.log(id_token);
    });

    let arr: string[] = this.document.location.href.split("code=");
    //console.log("Code:::", arr[1])
    if (arr[1]) {

      const myheader = new HttpHeaders().set(environment.contentTypeKey, environment.contentTypeValue);
      let body = new HttpParams();

      body = body.set(environment.grantTypeKey, environment.grantTypeValue);
      body = body.set(environment.clientIdKey, environment.clientIdValue);
      body = body.set(environment.codeKey, arr[1]);
     // body = body.set(environment.redirectUrlKey, environment.redirectUrlValue);
      body = body.set(environment.scope, environment.scopevalue);
      body = body.set('', '');

      return this.http.post(environment.codeToTokenServiceUrl, body, { headers: myheader })
      //.subscribe(data => {
      //  localStorage.setItem(environment.idTokenKey, data[environment.idTokenKey])
      //  localStorage.setItem(environment.accessToken, data[environment.accessToken])
      //  localStorage.setItem(environment.refreshToken, data[environment.refreshToken])
      //  localStorage.setItem('isLoggedIn', 'true'),
      //});
    }
  }
}
