import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { RepositoryService } from './../service/repository.service';
import { AzuressorequestsComponent } from '../azuressorequests/azuressorequests.component';
import { IAzureADRequests } from '../interface/azureadssorequest';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AadactionsdialogComponent } from '../aadactionsdialog/aadactionsdialog.component';

@Component({
  selector: 'app-azureadssoactionrequests',
  templateUrl: './azureadssoactionrequests.component.html',
  styleUrls: ['./azureadssoactionrequests.component.css']
})
export class AzureadssoactionrequestsComponent implements OnInit {
  editForm: FormGroup;
  json: string;
  submitted: boolean = false;
  action: string;
  local_data: any;
  isAdmin = false;
  loggedInUser: string = "";
  showAccessAPI: boolean = false;
  showXMLMetadata: boolean = false;
  showManualMetadata: boolean = false;
  accessAPIPreValue: string = ""
  metadataXMLPreValue: string = ""
  spIDPreValue: string = ""
  ASCUrlPreValue: string = ""
  logoutRequestURLPreValue: string = ""
  logoutResponseURLPreValue: string = ""
  isDisabledState: boolean = true
  showRejectedComments : boolean = false
  loading = true;
  showRequestorActionsButtons: boolean = false
  showApproverActionsButtons: boolean = false
  showAOpsActionsButtons: boolean = false
  requestorCancelledComments: string = ""
  approverRejectcomments: string = ""
  OpsRejectcomments: string = ""
  OpsComments: string = ""
  rejectedDisplayComments: string = ""

  constructor(private dialog: MatDialog, private fb: FormBuilder, private auth: AuthService, private rs: RepositoryService, public dialogRef: MatDialogRef<AzuressorequestsComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAzureADRequests) {

    this.local_data = data;
    //console.log("data in dialog:::::::::"+ JSON.stringify(data));
    //console.table(this.local_data);
    //console.log("data in dialog:::::::::" + data['AccountInfo']['accountOwners']);
    console.table(this.local_data);
    this.loggedInUser = sessionStorage.getItem("wwid");
    this.action = this.local_data['userActioning'];
    //console.log(this.action)
    //console.log(this.local_data['azureADRequests']['requestType'])
    //console.log(this.local_data['azureADRequests']['appRegType'])
    this.accessAPIPreValue = this.local_data['azureADRequests']['accessAPI'];
    this.metadataXMLPreValue = this.local_data['azureADRequests']['metadataXML'];
    this.spIDPreValue = this.local_data['azureADRequests']['spID'];
    this.ASCUrlPreValue = this.local_data['azureADRequests']['ASCUrl'];
    this.logoutRequestURLPreValue = this.local_data['azureADRequests']['logoutRequestURL'];
    this.logoutResponseURLPreValue = this.local_data['azureADRequests']['logoutResponseURL'];
    if (this.action == "ReSubmit") {
      if(this.local_data['azureADRequests']['requestStatus'] == "Open-Rejected"){
        this.showRejectedComments = true
        this.rejectedDisplayComments = this.local_data['azureADRequests']['approverRejectcomments']

      }
      else if (this.local_data['azureADRequests']['requestStatus'] == "Open-OpsRejected"){
        this.showRejectedComments = true
        this.rejectedDisplayComments = this.local_data['azureADRequests']['OpsRejectcomments']
      }
      else{
        this.showRejectedComments = false
      }
      
      //this.approverRejectcomments = this.local_data['azureADRequests']['approverRejectcomments']
      this.showRequestorActionsButtons = true;
      this.showApproverActionsButtons = false;
      this.showAOpsActionsButtons = false;
      this.isDisabledState = false;
      this.editForm = this.fb.group({
        appidname: [this.local_data['azureADRequests']['appRepoName'], [Validators.required]],

        //appidname:  [{ value: this.local_data['azureADRequests']['appRepoName'] }],
        appidownername: [this.local_data['azureADRequests']['appTechOwnerName']],
        appidownerWWID: [this.local_data['azureADRequests']['approverID']],
        appidBC: [this.local_data['azureADRequests']['appBC']],
        redirectURL: [this.local_data['azureADRequests']['redirectURL']],
        appidRC: [this.local_data['azureADRequests']['appRC']],
        appidBU: [this.local_data['azureADRequests']['appBU']],
        appid: [this.local_data['azureADRequests']['appRepoID']],
        appregname: [this.local_data['azureADRequests']['appRegName']],
        headerresponse: [this.local_data['azureADRequests']['headerResponse'], [Validators.required]],
        metadataXML: [this.local_data['azureADRequests']['metadataXML'], [Validators.required]],
        environment: [this.local_data['azureADRequests']['environment']],
        requestType: [this.local_data['azureADRequests']['requestType']],
        appType: [this.local_data['azureADRequests']['appRegType']],
        serviceDetails: [this.local_data['azureADRequests']['serviceDetails']],
        accessAPI: [this.local_data['azureADRequests']['accessAPI'], [Validators.required]],
        spID: [this.local_data['azureADRequests']['spID'], [Validators.required]],
        ASCUrl: [this.local_data['azureADRequests']['ASCUrl'], [Validators.required]],
        logoutRequestURL: [this.local_data['azureADRequests']['logoutRequestURL'], [Validators.required]],
        logoutResponseURL: [this.local_data['azureADRequests']['logoutResponseURL'], [Validators.required]],
        comments: [this.local_data['azureADRequests']['comments'], [Validators.required]],
        requestID: [this.local_data['azureADRequests']['requestID']],
        requestStatus: [this.local_data['azureADRequests']['requestStatus']],
        requestorWWID: [this.local_data['azureADRequests']['requestorWWID']],
        requestorName: [this.local_data['azureADRequests']['requestorName']],
        BundleID: [this.local_data['azureADRequests']['BundleID']],
        Packagename: [this.local_data['azureADRequests']['Packagename']],
        Signaturehash: [this.local_data['azureADRequests']['Signaturehash']],
        customredirectURL: [this.local_data['azureADRequests']['customredirectURL']],
        InputTokConf: [this.local_data['azureADRequests']['InputTokConf']],
        InputAPIPerm: [this.local_data['azureADRequests']['InputAPIPerm']],
        claimsattribute: [this.local_data['azureADRequests']['claimsattribute']]
      });
      if (this.local_data['azureADRequests']['requestType'] == 'Client Registration') {
        this.showAccessAPI = true;
        this.editForm.controls.accessAPI.setValue(this.accessAPIPreValue);
      }
      else if (this.local_data['azureADRequests']['requestType'] == 'SPN (Service Principal Name)') {
        this.showAccessAPI = false;
        this.editForm.controls.accessAPI.setValue("-NA-");
        this.editForm.controls.appregname.setValue("XXX_All_WebApp01_SPN");
      }
      else if (this.local_data['azureADRequests']['requestType'] == 'Service to Service') {
        this.showAccessAPI = false;
        this.editForm.controls.accessAPI.setValue("-NA-");
        this.editForm.controls.appregname.setValue("XXX_All_WebApp01_S2S");
      }
      else if (this.local_data['azureADRequests']['requestType'] == 'SAML') {
        this.showAccessAPI = false;
        this.editForm.controls.accessAPI.setValue("-NA-");
        this.editForm.controls.appregname.setValue("XXX_All_WebApp01_SAML");
      }
      else {
        console.log("Do Nothing")
      }

      if (this.local_data['azureADRequests']['serviceDetails'] == 'Load From Provider Metadata') {
        this.showXMLMetadata = true;
        this.showManualMetadata = false;
        this.editForm.controls.metadataXML.setValue(this.metadataXMLPreValue);
        this.editForm.controls.spID.setValue("-NA-");
        this.editForm.controls.ASCUrl.setValue("-NA-");
        this.editForm.controls.logoutRequestURL.setValue("-NA-");
        this.editForm.controls.logoutResponseURL.setValue("-NA-");
      }
      else {
        this.showXMLMetadata = false;
        this.showManualMetadata = true;
        this.editForm.controls.metadataXML.setValue("-NA-");
        this.editForm.controls.spID.setValue(this.spIDPreValue);
        this.editForm.controls.ASCUrl.setValue(this.ASCUrlPreValue);
        this.editForm.controls.logoutRequestURL.setValue(this.logoutRequestURLPreValue);
        this.editForm.controls.logoutResponseURL.setValue(this.logoutResponseURLPreValue);

      }
    }

    else if (this.action == "OwnerActions" || this.action == "All") {
      this.showRejectedComments = false

      if (this.action == "OwnerActions") {
        if (sessionStorage.getItem("Role").includes("CloudOps")) {
          this.showRequestorActionsButtons = false;
          this.showApproverActionsButtons = false;
          this.showAOpsActionsButtons = true;
        }
        else {
          this.showRequestorActionsButtons = false;
          this.showApproverActionsButtons = true;
          this.showAOpsActionsButtons = false;
        }

      }
      else {
        this.showRequestorActionsButtons = false;
        this.showApproverActionsButtons = false;
        this.showAOpsActionsButtons = false;
      }

      this.isDisabledState = true;
      this.editForm = this.fb.group({
        //   accountEmail: [{ value: this.local_data['AccountInfo']['accountEmail'], disabled: true }],
        appidname: [this.local_data['azureADRequests']['appRepoName'], [Validators.required]],
        //appidname:  [{ value: this.local_data['azureADRequests']['appRepoName'] }],
        appidownername: [this.local_data['azureADRequests']['appTechOwnerName']],
        appidownerWWID: [this.local_data['azureADRequests']['approverID']],
        appidBC: [this.local_data['azureADRequests']['appBC']],
        redirectURL: [this.local_data['azureADRequests']['redirectURL']],
        appidRC: [this.local_data['azureADRequests']['appRC']],
        appidBU: [this.local_data['azureADRequests']['appBU']],
        appid: [this.local_data['azureADRequests']['appRepoID']],
        appregname: [this.local_data['azureADRequests']['appRegName']],
        headerresponse: [this.local_data['azureADRequests']['headerResponse'], [Validators.required]],
        metadataXML: [this.local_data['azureADRequests']['metadataXML'], [Validators.required]],
        environment: [this.local_data['azureADRequests']['environment']],
        requestType: [this.local_data['azureADRequests']['requestType']],
        appType: [this.local_data['azureADRequests']['appRegType']],
        //appType: [{ value: this.local_data['azureADRequests']['appRegType'], disabled: true }],
        serviceDetails: [this.local_data['azureADRequests']['serviceDetails']],
        accessAPI: [this.local_data['azureADRequests']['accessAPI'], [Validators.required]],
        spID: [this.local_data['azureADRequests']['spID'], [Validators.required]],
        ASCUrl: [this.local_data['azureADRequests']['ASCUrl'], [Validators.required]],
        logoutRequestURL: [this.local_data['azureADRequests']['logoutRequestURL'], [Validators.required]],
        logoutResponseURL: [this.local_data['azureADRequests']['logoutResponseURL'], [Validators.required]],
        comments: [this.local_data['azureADRequests']['comments'], [Validators.required]],
        requestID: [this.local_data['azureADRequests']['requestID']],
        requestStatus: [this.local_data['azureADRequests']['requestStatus']],
        requestorWWID: [this.local_data['azureADRequests']['requestorWWID']],
        requestorName: [this.local_data['azureADRequests']['requestorName']],
        BundleID: [this.local_data['azureADRequests']['BundleID']],
        Packagename: [this.local_data['azureADRequests']['Packagename']],
        Signaturehash: [this.local_data['azureADRequests']['Signaturehash']],
        customredirectURL: [this.local_data['azureADRequests']['customredirectURL']],
        InputTokConf: [this.local_data['azureADRequests']['InputTokConf']],
        InputAPIPerm: [this.local_data['azureADRequests']['InputAPIPerm']],
        claimsattribute: [this.local_data['azureADRequests']['claimsattribute']]

      });
      if (this.local_data['azureADRequests']['requestType'] == 'Client Registration') {
        this.showAccessAPI = true;
        this.editForm.controls.accessAPI.setValue(this.accessAPIPreValue);
      }
      else if (this.local_data['azureADRequests']['requestType'] == 'SPN (Service Principal Name)') {
        this.showAccessAPI = false;
        this.editForm.controls.accessAPI.setValue("-NA-");
        this.editForm.controls.appregname.setValue("XXX_All_WebApp01_SPN");
      }
      else if (this.local_data['azureADRequests']['requestType'] == 'Service to Service') {
        this.showAccessAPI = false;
        this.editForm.controls.accessAPI.setValue("-NA-");
        this.editForm.controls.appregname.setValue("XXX_All_WebApp01_S2S");
      }
      else if (this.local_data['azureADRequests']['requestType'] == 'SAML') {
        this.showAccessAPI = false;
        this.editForm.controls.accessAPI.setValue("-NA-");
        this.editForm.controls.appregname.setValue("XXX_All_WebApp01_SAML");
      }
      else {
        
        console.log("Do Nothing")
      }

      if (this.local_data['azureADRequests']['serviceDetails'] == 'Load From Provider Metadata') {
        this.showXMLMetadata = true;
        this.showManualMetadata = false;
        this.editForm.controls.metadataXML.setValue(this.metadataXMLPreValue);
        this.editForm.controls.spID.setValue("-NA-");
        this.editForm.controls.ASCUrl.setValue("-NA-");
        this.editForm.controls.logoutRequestURL.setValue("-NA-");
        this.editForm.controls.logoutResponseURL.setValue("-NA-");
      }
      else {
        this.showXMLMetadata = false;
        this.showManualMetadata = true;
        this.editForm.controls.metadataXML.setValue("-NA-");
        this.editForm.controls.spID.setValue(this.spIDPreValue);
        this.editForm.controls.ASCUrl.setValue(this.ASCUrlPreValue);
        this.editForm.controls.logoutRequestURL.setValue(this.logoutRequestURLPreValue);
        this.editForm.controls.logoutResponseURL.setValue(this.logoutResponseURLPreValue);

      }

    }

  }

  ngOnInit() {

    //console.log("data in dialog:::::::::" + this.local_data['accountName']);




  }

  get fd() {
    return this.editForm.controls;
  }


  get comments() {
    return this.editForm.get('comments');
  }
  get metadataXML() {
    return this.editForm.get('metadataXML');
  }
  get headerresponse() {
    return this.editForm.get('headerresponse');
  }

  get accessAPI() {
    return this.editForm.get('accessAPI');
  }


  onSelectionChangeReqType(event: MatRadioChange) {
    this.loading = true;
    //console.log(event.value)
    if (event.value == 'Client Registration') {
      this.showAccessAPI = true;
      this.editForm.controls.accessAPI.setValue(this.accessAPIPreValue);
      this.editForm.controls.appregname.setValue("XXX_" + this.editForm.controls.environment.value + "_" + this.editForm.controls.appid.value + "_WebApp01_Clt01");
    }
    else if (event.value == 'SPN (Service Principal Name)') {
      this.showAccessAPI = false;
      this.editForm.controls.accessAPI.setValue("-NA-");
      this.editForm.controls.appregname.setValue("XXX_" + this.editForm.controls.environment.value + "_" + this.editForm.controls.appid.value + "_WebApp01_SPN");
    }
    else if (event.value == 'Service to Service') {
      this.showAccessAPI = false;
      this.editForm.controls.accessAPI.setValue("-NA-");
      this.editForm.controls.appregname.setValue("XXX_" + this.editForm.controls.environment.value + "_" + this.editForm.controls.appid.value + "_WebApp01_S2S");
    }
    else if (event.value == 'SAML') {
      this.showAccessAPI = false;
      this.editForm.controls.accessAPI.setValue("-NA-");
      this.editForm.controls.appregname.setValue("XXX_" + this.editForm.controls.environment.value + "_" + this.editForm.controls.appid.value + "_WebApp01_SAML");
    }
    this.editForm.controls.requestType.setValue(event.value);


  }
  onSelectionChangeAppType(event: MatRadioChange) {
    this.loading = true;

    //console.log(event.value)
    this.editForm.controls.appType.setValue(event.value);
  }
  onSelectionChangeserviceDetails(event: MatRadioChange) {

    //console.log(event.value)
    this.editForm.controls.serviceDetails.setValue(event.value);

    this.loading = true;
    if (event.value == 'Load From Provider Metadata') {
      this.showXMLMetadata = true;
      this.showManualMetadata = false;
      this.editForm.controls.metadataXML.setValue(this.metadataXMLPreValue);
      this.editForm.controls.spID.setValue("-NA-");
      this.editForm.controls.ASCUrl.setValue("-NA-");
      this.editForm.controls.logoutRequestURL.setValue("-NA-");
      this.editForm.controls.logoutResponseURL.setValue("-NA-");

    }
    else {
      this.showXMLMetadata = false;
      this.showManualMetadata = true;
      this.editForm.controls.metadataXML.setValue("-NA-");
      this.editForm.controls.spID.setValue("");
      this.editForm.controls.ASCUrl.setValue("");
      this.editForm.controls.logoutRequestURL.setValue("");
      this.editForm.controls.logoutResponseURL.setValue("");

    }

  }
  doActionSave(actionType: string) {

    //console.log("actionType::", actionType)
    this.submitted = true;
    if (this.editForm.invalid) {
      return;
    }
    let appidname = this.editForm.getRawValue().appidname;
    let appidownername = this.editForm.getRawValue().appidownername;
    let appidownerWWID = this.editForm.getRawValue().appidownerWWID;
    let appidBC = this.editForm.getRawValue().appidBC;
    let appidRC = this.editForm.getRawValue().appidRC;
    let appidBU = this.editForm.getRawValue().appidBU;
    let redirectURL = this.editForm.getRawValue().redirectURL;
    let appid = this.editForm.getRawValue().appid;
    let appregname = this.editForm.getRawValue().appregname;
    let headerresponse = this.editForm.getRawValue().headerresponse;
    let metadataXML = this.editForm.getRawValue().metadataXML;
    let environment = this.editForm.getRawValue().environment;
    let requestType = this.editForm.getRawValue().requestType;
    let appType = this.editForm.getRawValue().appType;
    let serviceDetails = this.editForm.getRawValue().serviceDetails;
    let accessAPI = this.editForm.getRawValue().accessAPI;
    let spID = this.editForm.getRawValue().spID;
    let ASCUrl = this.editForm.getRawValue().ASCUrl;
    let logoutRequestURL = this.editForm.getRawValue().logoutRequestURL;
    let logoutResponseURL = this.editForm.getRawValue().logoutResponseURL;
    let comments = this.editForm.getRawValue().comments;
    let requestorWWID = this.editForm.getRawValue().requestorWWID;
    let requestorName = this.editForm.getRawValue().requestorName;
    let requestUpdatedBy = sessionStorage.getItem("Username")
    let requestIDValue = this.editForm.getRawValue().requestID;

    let BundleID = this.editForm.getRawValue().BundleID;
    let Packagename = this.editForm.getRawValue().Packagename;
    let Signaturehash = this.editForm.getRawValue().Signaturehash;
    let customredirectURL = this.editForm.getRawValue().customredirectURL;
    let InputTokConf = this.editForm.getRawValue().InputTokConf;
    let InputAPIPerm = this.editForm.getRawValue().InputAPIPerm;
    let claimsattribute = this.editForm.getRawValue().claimsattribute;

    //approverRejectcomments : string = ""
    //OpsRejectcomments : string = ""
    //opsComments : string = ""


    if (actionType == "Cancelled") {
      const dialogConfig = new MatDialogConfig();
      //console.table(awsAccount);
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        userAction: actionType,
        accessRequests: this.editForm.getRawValue().requestID
      };
      const dialogRef = this.dialog.open(AadactionsdialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
          //console.log("Dialog output:", data)
          //console.table(dialogData)
          let userResponse = dialogData['event'];

          if (userResponse == 'Save') {
            this.requestorCancelledComments = dialogData['data'];
            this.rs.actionAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName, requestUpdatedBy, requestIDValue, this.requestorCancelledComments, this.approverRejectcomments, this.OpsRejectcomments, this.OpsComments, actionType, BundleID, Packagename, Signaturehash, customredirectURL, InputTokConf, InputAPIPerm, claimsattribute).subscribe(res => {
              //console.log(res);
              let status = res['statusCode'];
              //console.log(status);
              this.dialogRef.close({ event: actionType, data: status });
            })
          }

          else {

            //DO Nothing


          }
        }
      );


    }

    else if (actionType == "OwnerApproved") {
      this.rs.actionAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName, requestUpdatedBy, requestIDValue, this.requestorCancelledComments, this.approverRejectcomments, this.OpsRejectcomments, this.OpsComments, actionType, BundleID, Packagename, Signaturehash, customredirectURL, InputTokConf, InputAPIPerm, claimsattribute).subscribe(res => {
        //console.log(res);
        let status = res['statusCode'];
        //console.log(status);
        this.dialogRef.close({ event: actionType, data: status });
      })

    }

    else if (actionType == "ReSubmit") {
      //console.log("Resubitted",this.approverRejectcomments)
      this.rs.actionAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName, requestUpdatedBy, requestIDValue, this.requestorCancelledComments, this.approverRejectcomments, this.OpsRejectcomments, this.OpsComments, actionType, BundleID, Packagename, Signaturehash, customredirectURL, InputTokConf, InputAPIPerm, claimsattribute).subscribe(res => {
        //console.log(res);
        let status = res['statusCode'];
        //console.log(status);
        this.dialogRef.close({ event: actionType, data: status });
      })

    }

    else if (actionType == "OpsApproved") {
      this.rs.actionAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName, requestUpdatedBy, requestIDValue, this.requestorCancelledComments, this.approverRejectcomments, this.OpsRejectcomments, this.OpsComments, actionType, BundleID, Packagename, Signaturehash, customredirectURL, InputTokConf, InputAPIPerm, claimsattribute).subscribe(res => {
        //console.log(res);
        let status = res['statusCode'];
        //console.log(status);
        this.dialogRef.close({ event: actionType, data: status });
      })

    }

    else if (actionType == "OwnerRejected") {
      const dialogConfig = new MatDialogConfig();
      //console.table(awsAccount);
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        userAction: actionType,
        accessRequests: this.editForm.getRawValue().requestID
      };
      const dialogRef = this.dialog.open(AadactionsdialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
          //console.log("Dialog output:", data)
          //console.table(dialogData)
          let userResponse = dialogData['event'];

          if (userResponse == 'Save') {
            this.approverRejectcomments = dialogData['data'];
            this.rs.actionAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName, requestUpdatedBy, requestIDValue, this.requestorCancelledComments, this.approverRejectcomments, this.OpsRejectcomments, this.OpsComments, actionType, BundleID, Packagename, Signaturehash, customredirectURL, InputTokConf, InputAPIPerm, claimsattribute).subscribe(res => {
              //console.log(res);
              let status = res['statusCode'];
              //console.log(status);
              this.dialogRef.close({ event: actionType, data: status });
            })
          }

          else {

            //DO Nothing


          }
        }
      );

    }

    else if (actionType == "OpsRejected") {
      const dialogConfig = new MatDialogConfig();
      //console.table(awsAccount);
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        userAction: actionType,
        accessRequests: this.editForm.getRawValue().requestID
      };
      const dialogRef = this.dialog.open(AadactionsdialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
          //console.log("Dialog output:", data)
          //console.table(dialogData)
          let userResponse = dialogData['event'];

          if (userResponse == 'Save') {
            this.OpsRejectcomments = dialogData['data'];
            this.rs.actionAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName, requestUpdatedBy, requestIDValue, this.requestorCancelledComments, this.approverRejectcomments, this.OpsRejectcomments, this.OpsComments, actionType, BundleID, Packagename, Signaturehash, customredirectURL, InputTokConf, InputAPIPerm, claimsattribute).subscribe(res => {
              //console.log(res);
              let status = res['statusCode'];
              //console.log(status);
              this.dialogRef.close({ event: actionType, data: status });
            })
          }

          else {

            //DO Nothing


          }
        }
      );

    }






  }

  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}

